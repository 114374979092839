<template>
    <div>
        <img class="img0" width="100%" src="@/assets/other/background.png">
        <div style="height: 100px">
            <router-link class="router-link" :to="{path:'home'}">
                
                <el-button type="primary" class="back_Button"><img style="height: 18px" src="@/assets/home/back.png">&nbsp;&nbsp;Retake Quiz</el-button>
            </router-link>
        </div>
        <div class="title">
            <a class="title_a">Quiz Results</a>
        </div>
        <div class="detial">
            <div class="detial_1">
                <div class="detial_title">
                    <span v-if="this.form.wuxing.simple_desc == '金'">jin</span>
                    <span v-if="this.form.wuxing.simple_desc == '木'">mu</span>
                    <span v-if="this.form.wuxing.simple_desc == '水'">shui</span>
                    <span v-if="this.form.wuxing.simple_desc == '火'">huo</span>
                    <span v-if="this.form.wuxing.simple_desc == '土'">tu</span>
                </div>
                <div class="detial_main">
                    <p>detail_desc": "{{ this.form.wuxing.detail_desc }}", //五行纳音</p>
                    <p>"simple_desc": "{{ this.form.wuxing.simple_desc }}",//五行属性</p>
                    <p>"simple_description": "{{ this.form.wuxing.simple_description }}", //五行分析</p>
                    <p>"sx": "{{ this.form.sx }}"，//生肖信息</p>
                    <p>Bazi (Eight Characters) strength: {{ this.form.xiyongshen.qiangruo }}",//八字强弱</p>
                    <p>"xiyongshen": "{{ this.form.xiyongshen.xiyongshen }}",//以金为喜用神</p>
                    <p>"jin_score_percent": "{{ this.form.xiyongshen.jin_score_percent }}",//五行金能量占比</p>
                    <p>"mu_score_percent": "{{ this.form.xiyongshen.mu_score_percent }}"，//五行木能量占比</p>
                    <p>"shui_score_percent": "{{ this.form.xiyongshen.shui_score_percent }}",//五行水能量占比</p>
                    <p>"huo_score_percent": "{{ this.form.xiyongshen.huo_score_percent }}",//五行火能量占比</p>
                    <p>"tu_score_percent": "{{ this.form.xiyongshen.tu_score_percent }}",//五行土能量占比</p>
                </div>
                <div>
                    <div class="detial_end">
                        Your Exclusive Talisman.
                    </div>
                    <div>
                        <img class="detial_img" src="@/assets/other/down.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="talisman">
            <el-card class="talisman_card" :body-style="{ padding: '0px' }" shadow="hover">
                <div class="card">
                    <div style="width: 42%">
                        <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="card_image">
                    </div>
                    <div class="card_detial">
                        <div style="margin-top: 5%; text-align:justify">
                            <span class="card_a">Success Feng Shui Antique Jade Stone Tree</span>
                        </div>
                        <div>
                            <el-button type="primary" class="card_Button">VIEW PRODUCT</el-button>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
        <div style="width: 98%; margin-left: 2%; margin-top: 140px">
            <div style="width: 90%; margin-left: 5%">
                <span class="enjoy_a">You Might Enjoy This</span>
            </div>
            <div style="margin-top: 30px;display: flex">
                <div class="type">
                    <img class="type_img" style="background-color: #623B7C">
                </div>
                <div class="type">
                    <img class="type_img" style="background-color: #623B7C">
                </div>
                <div class="type">
                    <img class="type_img" style="background-color: #623B7C">
                </div>
                <div class="type">
                    <img class="type_img" style="background-color: #623B7C">
                </div>
                <div class="type">
                    <img class="type_img" style="background-color: #623B7C">
                </div>
            </div>
        </div>
    </div>
</template>


<script>



export default {
    name: "Orders",
    data() {
        return {
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
            form: {},
            
        }
    },
    created() {
       this.load()
    },
    methods: {
        load(){
            this.request.get('/bazicesuan',{
            }).then((res)=>{
                console.log(res.data)
                this.form = res.data
            }).catch(error=>{
                console.log(error);
            })
        },
        
    }
}
</script>

<style scoped>
.img0 {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index:-1;
}
.back_Button {
    margin-left: 10%;
    margin-top: 130px;
    padding-right: 30px;
    width: 250px;
    height: 50px;
    background-color: #EAEAEC;
    color: #818181;
    font-size: 25px;
    text-align: center;
    border-radius: 25px;
    border: 1px solid #A4A4A4;
}
.title {
    text-align: center;
    margin-top: 50px;
}
.title_a {
    font-size: 70px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 5px;
    word-spacing: 5px;
}
.detial {
    height: 1520px; 
    width:44%; 
    margin-left: 28%; 
    margin-top: 80px; 
    padding: 12px;
    border: 2px solid #623B7C;
}
.detial_1 {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.76);
    padding: 50px;
    text-align: center;
}
.detial_title {
    margin-top: 10px;
    font-size: 70px;
    font-family: 'Times New Roman', Times, serif;
}
.detial_main {
    margin-top: 65px;
    font-size: 24px;
    line-height: 33px;
}
.detial_end {
    margin-top: 80px;
    font-size: 40px;
}
.detial_img {
    margin-top: 50px;
    width: 70px;
}
.talisman  {
    width: 50%;
    margin-left: 25%;
    margin-top: 140px;
}
.talisman_card {
    height: 420px;
    background-color: rgba(255, 255, 255, 0.76);
}
.card {
    display: flex;
    padding: 20px;
}
.card_image {
    width: 100%;
}
.card_detial {
    width: 58%;
    padding: 50px;
    padding-right: 20px;
    text-align: left;
}
.card_a {
    font-size: 37px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 1px;
}
.card_Button {
    width: 300px;
    height: 70px;
    background-color: #310052;
    color: #fff;
    font-size: 25px;
    letter-spacing: 1px;
    margin-top: 20%;
    border-radius: 35px;
    border: #fff;
    border-style: double;
}
.enjoy_a {
    font-size: 52px;
    font-family: 'Times New Roman', Times, serif;
}
.type {
    width: 20%;
    text-align: center;
}
.type_img {
    width: 85%;
    height: 310px;
}
</style>